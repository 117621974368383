import { initReactWidget } from 'src'

async function enableMocking() {
    if (import.meta.env.REACT_APP_MSW === 'true') {
        const { worker } = await import('./mocks/browser')
        return worker.start({
            onUnhandledRequest: 'bypass'
        })
    }
}

enableMocking().then(initReactWidget)
